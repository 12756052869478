import { assign, createMachine } from 'xstate'
import { helperActions } from '@/utils/machine-helpers'
import { logger } from "@/utils/index.js";
import { subscribeWithUid, flagAssistantMessage } from "@/lib/data/assistant.js";
import { askAssistant } from "@/lib/assistant.js";
import { firebaseAuth } from "@/modules/firebase.js";

const initialState = {
  errors: [],
}

export default createMachine(
  {
    id: 'assistant',

    predictableActionArguments: true,

    initial: 'idle',

    context: {
      ...initialState,
    },

    invoke: {
      src: "subscribeToAssistant",
      onError: {
        actions: (_, event) => {
          // This is here to handle the error when the user is not logged in
          console.log(event)
        },
      }
    },

    states: {
      idle: {
        on: {
          "ASSISTANT_SNAPSHOT":
          {
            actions: ["setAssistantData", "setAssistantMessages", "setActiveMessage"],
          },
          "ASSISTANT_SNAPSHOT_FAILED": {
            target: "error",
            actions: ["assignErrors", "trackErrors"],
          },
          "ASK_ASSISTANT": {
            target: "asking",
          },
          "BAD_FEEDBACK_RECEIVED": {
            target: "sendingFeedback",
          },
          "SELECT_MESSAGE": {
            target: "idle",
            actions: ["setActiveMessage"],
          },
        },
      },
      asking: {
        invoke: { src: "askAssistant" },
        on: {
          "ASSISTANT_SNAPSHOT": {
            target: "idle",
            actions: ["setAssistantData", "setAssistantMessages", "setActiveMessage"],
          },
          "ASSISTANT_SNAPSHOT_FAILED": {
            target: "error",
            actions: ["assignErrors", "trackErrors"],
          },
          "SELECT_MESSAGE": {
            target: "idle",
            actions: ["setActiveMessage"],
          },
        },
      },
      error: {
        on: {
          "ASSISTANT_SNAPSHOT": {
            target: "idle",
            actions: ["setAssistantData", "setAssistantMessages", "setActiveMessage", "resetErrors"],
          },
          "ASSISTANT_SNAPSHOT_FAILED": {
            target: "error",
            actions: ["assignErrors", "trackErrors"],
          },
          "ASK_ASSISTANT": {
            target: "asking",
          },
          "SELECT_MESSAGE": {
            target: "idle",
            actions: ["setActiveMessage"],
          },
        },
      },
      sendingFeedback: {
        invoke: {
          src: "flagAssistantMessage",
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"],
          },
          onDone: {
            target: "idle",
            actions: [
              {
                type: "showToast",
                message: "Feedback submitted. Thank you 🙏",
                toastType: "success",
                toastOptions: {
                  duration: 5000,
                  dismissible: true,
                },
              },
            ]
          },
        },
      }
    },
  },
  {
    actions: {
      ...helperActions,
      setAssistantData: assign({
        assistantData: (_, {assistantData}) => assistantData,
      }),
      setActiveMessage: assign({
        activeMessage: ({assistantData}, {id}) => {
          if (!id) {
            // If no id is passed, return the first message
            return assistantData?.messages?.[0]
          }

          // Otherwise, return the message with the matching id
          return assistantData?.messages?.find((message) => message.id === id);
        },
      }),
      setAssistantMessages: assign({
        messages: (_, { assistantData }) => {
          const messages = assistantData?.messages || [];

          // for each message change line breaks to br tags
          messages.forEach((message) => {
            message.assistant = message?.assistant?.replace(/\n/g, "<br />");

            // convert markdown links to html links
            message.assistant = message?.assistant?.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
          });

          // reverse the messages so that the latest message is at the bottom
          return messages.reverse();
        }
      }),
    },
    services: {
      flagAssistantMessage: (_, { messageId }) => {
        if (!firebaseAuth?.currentUser) {
          throw new Error("Please sign in to use the assistant");
        }

        return flagAssistantMessage(firebaseAuth?.currentUser?.uid, messageId);
      },
      subscribeToAssistant: () => async (send) => {
        if (!firebaseAuth?.currentUser) {
          send({
            type: "UNAUTHENTICATED",
            errorMessage: "Please sign in to use the assistant",
          });

          return;
        }

        const onUpdate = (doc) => {
          if (doc.exists()) {
            const assistantData = doc?.data();

            send({ type: "ASSISTANT_SNAPSHOT", assistantData });
          } else {
            send({
              type: "ASSISTANT_SNAPSHOT_FAILED",
              errorMessage: "Assistant data not found",
            });
          }
        };

        const onError = (error) => {
          if (error?.code === "permission-denied") {
            logger.warn("Assistant data subscription failed as permission was denied");
          } else {
            send({
              type: "ASSISTANT_SNAPSHOT_FAILED",
              errorMessage: "Assistant data not found",
            });
          }
        };

        const unsubscribe = await subscribeWithUid(firebaseAuth?.currentUser?.uid, onUpdate, onError);

        return () => unsubscribe();
      },
      askAssistant: ({ assistantData }, { input, url }) => askAssistant(input, assistantData?.messages?.length > 0, url)
    }
  }
)
