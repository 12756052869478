import { getCurrentUser } from "vuefire";
import { useRouter, useRoute } from 'vue-router'

export const authenticatedRedirect = async () => {
  // Composables
  const router = useRouter();
  const route = useRoute();

  const currentUser = await getCurrentUser();

  // If we have a user redirect them to the redirect path after login
  if (currentUser) {
    const to =
      route?.query?.redirect && typeof route?.query?.redirect === "string"
        ? route?.query?.redirect
        : "/";

    router.push(to);
  }
}

export const isUserEmailVerified = (user) => {
  // Only check for users that registered after 23/04/2024
  if (user?.metadata?.createdAt > 1713850333000) {
    return user.emailVerified;
  } else {
    return true;
  }
}
