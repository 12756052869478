import { createApp } from "vue";
import PreviewMenu from "@/components/PreviewMenu/PreviewMenu.vue";

const addPreviewMenu = (el, binding, isUpdated) => {
  // check if the element already has a preview menu
  if (el.querySelector(".preview-menu-container")) {
    return;
  }

  // Create a new container
  const container = document.createElement("div");

  // Add a class to the container
  container.classList.add("preview-menu-container");

  // Add a class to the directive's element
  el.classList.add("preview-menu-wrapper");
  el.classList.add("relative");

  const app = createApp(PreviewMenu, {
    // Here we're passing the binding value as a prop to the component
    ...binding?.value,
    isUpdated,
  });

  // Mount the component to this new container.
  app.mount(container);

  // Append the container (with the component) to the directive's element.
  el.appendChild(container);
}

export const install = async ({ app }) => {
  app.directive("preview", {
    updated(el, binding) {
      addPreviewMenu(el, binding, true)
    },
    mounted(el, binding) {
      addPreviewMenu(el, binding, false)
    }
  });
};
