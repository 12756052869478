import { uid } from 'uid/single'
import { SSR } from '~/utils/constants';

/**
 * Get the user id from local storage or create a new one
 *
 * @returns {string} The user id
 */
export const setOrGetUserIdFromLocalStorage = () => {
  if (!SSR) {
    // Get the user id from local storage
    const userId = window?.localStorage.getItem('uid')

    // If the user id exists, return it
    if (userId) {
      return userId
    }

    // If the user id doesn't exist, create a new one
    const newId = `visitor-${uid()}`

    // Store the new user id in local storage
    window?.localStorage.setItem('uid', newId)

    // Return the new user id
    return newId
  }
}

export const updateUserIdInLocalStorage = (id = null) => {
  if (!SSR) {
    // Store the new user id in local storage
    window?.localStorage.setItem('uid', id)
  }
}
