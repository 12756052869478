import { H } from 'highlight.run';
import { SSR } from '~/utils/constants'
const { VITE_APP_ENV } = import.meta.env
// Setup our loggers...
// If we're in production send errors to error service or the console if it's Mixo admin...
let logger = {}

const MIXO_ADMIN = typeof window !== "undefined" && window?.localStorage.getItem("mixo-admin") === "true";

if (!SSR) {
  logger = { ...window?.console }

  logger.error = (err, context) => {
    if (VITE_APP_ENV === 'production' && !MIXO_ADMIN) {
      H.consumeError(err, err?.label, context);
    } else {
      console.group('👨‍💻:', err?.label || 'Error')
        console.info(context);

        if (err) {
          console.error(err)
        }
      console.groupEnd()
    }
  }

  logger.log = VITE_APP_ENV === 'production' && !MIXO_ADMIN ? () => {} : window?.console?.log

  logger.groupCollapsed = VITE_APP_ENV === 'production' && !MIXO_ADMIN ? () => { } : window?.console?.groupCollapsed
  logger.groupEnd = VITE_APP_ENV === 'production' && !MIXO_ADMIN ? () => { } : window?.console?.groupEnd

  logger.info = window?.console?.info
  logger.warn = window?.console?.warn
}


export {logger}
