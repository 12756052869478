

import __layout_0 from '/home/runner/work/mixo-app/mixo-app/client/app/src/layouts/default.vue'
export const layouts = {
'404': () => import('/home/runner/work/mixo-app/mixo-app/client/app/src/layouts/404.vue'),
'auth': () => import('/home/runner/work/mixo-app/mixo-app/client/app/src/layouts/auth.vue'),
'blank': () => import('/home/runner/work/mixo-app/mixo-app/client/app/src/layouts/blank.vue'),
'default': __layout_0,
'editor': () => import('/home/runner/work/mixo-app/mixo-app/client/app/src/layouts/editor.vue'),
'empty': () => import('/home/runner/work/mixo-app/mixo-app/client/app/src/layouts/empty.vue'),
'generator': () => import('/home/runner/work/mixo-app/mixo-app/client/app/src/layouts/generator.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
