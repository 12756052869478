// Docs: https://www.highlight.io/docs/getting-started/client-sdk/vuejs
import { H } from 'highlight.run';

import { DEVELOPMENT } from "~/utils/constants.js";

const setupErrorTracking = () => {
  // Ignore the following paths
  const paths = ["/sites/preview", "/sites/preview/"];

  if (paths.includes(window.location.pathname)) {
    return;
  }

  H.init(import.meta.env.VITE_HIGHLIGHT_PROJECT_ID, {
    environment: import.meta.env.VITE_APP_ENV,
    version: import.meta.env.VITE_GITHUB_SHA || import.meta.env.VITE_APP_ENV,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });
}

const errorHandler = (err, vm, info) => {
  // Emit component name and also the lifecycle hook the error occurred in if present
  var infoMessage = `Error in component: <${vm.$options.name} />\n`;
  if (info){
    infoMessage += `Error occurred during lifecycle hook: ${info}\n`;
  }

  if (DEVELOPMENT) {
    console.log(infoMessage);
    console.error(err);
    vm?.$options?.propsData && console.log("Props passed to component", vm.$options.propsData);
  } else {
    // if error message contains Async component timed out then ignore it
    const asyncTimeout = /Async component timed out/;
    if (asyncTimeout.test(err?.message)) {
      return;
    } else {
      // Track the native JS error
      H.consumeError(
        err,
        infoMessage,
        {
          component: vm.$options.name,
          propsData: vm.$options.propsData,
          info,
        }
      );
    }

  }
}

export { setupErrorTracking, errorHandler}
