<template>
  <div
    v-if="!userEmailVerified"
    class="text-center p-1 md:py-3 bg-yellow-100 text-xs sm:text-sm text-yellow-700 border-b border-yellow-200"
    data-testid="email-verification"
  >
    We have sent a verification email{{ userEmail ? ` to ${userEmail}` : "" }}. Please
    click the link in the email to verify your email address. If you have already verified
    your email, please
    <button type="button" class="text-blue-500 underline" @click="onRefreshPage">
      refresh
    </button>
    the page. If you didn't receive the email, please check your spam folder or
    <button
      type="button"
      class="text-blue-500 underline"
      :disabled="sendingEmail"
      @click="onResendClick"
    >
      {{ sendingEmail ? "sending..." : "resend the email" }}
    </button>.
  </div>
</template>

<script setup>
import { sendEmailVerification } from "firebase/auth";
import { firebaseAuth } from "@/modules/firebase.js";
import { useCurrentUser } from "vuefire";
import { isUserEmailVerified } from "@/composables";
import { logger } from "@/utils/index.js";

// Injections
const toast = inject("$toast");

// Composables
const route = useRoute();

// Data
const isPreviewRoute = eagerComputed(
  () => route?.name === "sites-preview" || route?.path === "/sites/preview"
);
const user = useCurrentUser();
const userEmailVerified = eagerComputed(() => {
  // if the url is /sites/preview or contains ai-website-builder, we don't need to check if the user is verified
  if (isPreviewRoute?.value || route?.path.includes("ai-website-builder")) {
    return true;
  }

  // passing in user.value reevaluates the computed when the user changes
  return isUserEmailVerified(user.value);
});
const userEmail = eagerComputed(() => user.value?.email);
const [sendingEmail, toggleSendingEmail] = useToggle(false);

// Methods
const onResendClick = async () => {
  toggleSendingEmail();

  try {
    await sendEmailVerification(firebaseAuth.currentUser);

    toast?.open({
      message: "Please check your email to verify your account 📧",
      type: "success",
      dismissible: true,
    });
  } catch (error) {
    logger.error(error);

    toast?.open({
      message: `Sorry there was an error sending the verification email. Please contact <a href='${
        import.meta.env.VITE_APP_URL
      }/support'>support</a>.`,
      type: "error",
      dismissible: true,
      duration: 0,
    });
  } finally {
    sendingEmail.value = false;
  }
};

const onRefreshPage = () => {
  location.reload();
};
</script>
