<template>
  <div
    :key="activeMessage?.id"
    class="space-y-3 rounded-lg shadow-md ring-1 ring-slate-100 bg-white overflow-hidden p-4"
  >
    <!-- User Input -->
    <div v-if="activeMessage?.user" class="flex-row-center gap-2">
      <IconTablerMessageBolt class="w-6 h-6 text-slate-700" />
      <p class="text-slate-700 font-semibold w-72">
        {{ activeMessage?.user }}
      </p>
    </div>

    <!-- Assistant Response -->
    <div v-if="activeMessage?.assistant" class="space-y-4 max-h-full overflow-y-auto">
      <div class="prose prose-slate" v-html="activeMessage?.assistant" />
      <!-- Buttons -->
      <div class="flex flex-wrap gap-2">
        <UiButton
          v-for="(button, idx) in activeMessage?.buttons"
          :key="idx"
          theme="brand-primary"
          size="xs"
          :title="`${button?.action} - ${button?.link}`"
          @click="$emit('button-click', button)"
        >
          {{ getButtonLabel(button) }}
        </UiButton>
      </div>

      <hr />

      <div
        v-if="!showFeedbackThanks && !sendingFeedback"
        class="flex-row-center gap-2 text-slate-500"
      >
        <p class="italic text-xs">
          Was this response helpful / correct?
        </p>
        <button
          type="button"
          class="hover:text-primary"
          @click="showFeedbackThanks = true"
        >
          <IconAkarIconsThumbsUp class="w-3 h-3 md:w-3.5 md:h-3.5" />
        </button>
        <button type="button" class="hover:text-primary" @click="onBadFeedback">
          <IconAkarIconsThumbsDown class="w-3 h-3 md:w-3.5 md:h-3.5" />
        </button>
      </div>

      <UiLoader v-if="sendingFeedback" size="sm" label="Sending feedback..." />

      <p v-if="showFeedbackThanks" class="text-xs text-teal-600">
        Feedback submitted! Thank you 🙏
      </p>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["bad-feedback", "button-click"]);

const props = defineProps({
  activeMessage: {
    type: Object,
    default() {
      return {};
    },
  },
  sendingFeedback: {
    type: Boolean,
    default: false,
  },
});


const [showFeedbackThanks] = useToggle(false);

const getButtonLabel = (button) => {
  if (button?.label) {
    return button.label;
  }

  const labels = {
    submitTicket: "Submit Ticket",
    refund: "Request Refund",
    paymentIssue: "Report Payment Issue",
  };

  return labels[button?.action];
};

const onBadFeedback = () => {
  emit("bad-feedback", props?.activeMessage?.id);
};
</script>
