import { callableFunction } from "@/lib/utils.js";

/**
 * This function asks assistant AI for a response
 *
 * @param {*} question The prompt to send to the assistant
 * @param {boolean} [isReply=false] Whether this is a reply to a previous prompt
 * @param {*} url The url to send to the assistant
 * @returns {*} Object with form component mapping
 */
const askAssistant = async(question, isReply = false, url) => {
  const mode = isReply ? "reply" : "new";
  const params = { prompt: question, mode };
  if(url) {
    params.url = url;
  }

  return callableFunction("askAssistant", params)
};

export {
  askAssistant
}
