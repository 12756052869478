<script>
import { ModalsContainer } from "vue-final-modal";
import { APP_ENV, DEVELOPMENT } from "~/utils/constants.js";
import { asyncComponentDefaults } from "@/composables";

const Maintenance = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/Maintenance.vue"),
});

const Sidebar = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/Sidebar/Sidebar.vue"),
});

const EmailVerification = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/EmailVerification/EmailVerification.vue"),
});

export default {
  name: "App",
  components: {
    ModalsContainer,
  },
  setup() {
    const route = useRoute();

    const [isSidebarActive, toggleSidebar] = useToggle(false);
    provide("isSidebarActive", isSidebarActive);
    provide("toggleSidebar", toggleSidebar);

    const scripts = ref([
      // Rewardful
      {
        children: `
            (function (w, r) { w._rwq = r; w[r] = w[r] || function () { (w[r].q = w[r].q || []).push(arguments) } })(window, 'rewardful');

            (function(){
              let referral_url = new URL(window.location.href);
              if (referral_url.searchParams.has('gclid') && referral_url.searchParams.has('via')) {
                setTimeout(function(){
                  document.cookie = "rewardful.referral=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${
                    import.meta.env.VITE_MIXO_DOMAIN
                  }; Secure; SameSite=None; Priority=Medium";
                  console.warn("Deleted referral cookie as visit came from Google Ads (forbidden by Terms of Service)")
                }, 2000)
              }
            })();
          `,
      },
      {
        src: "https://r.wdfl.co/rw.js",
        async: true,
        "data-rewardful": "35dd66",
      },
    ]);

    // a computed that determines if we're on the preview route
    const isPreviewRoute = eagerComputed(
      () => route?.name === "sites-preview" || route?.path === "/sites/preview"
    );

    // Don't load any third party scripts on the sites preview page
    if (isPreviewRoute?.value) {
      scripts.value = [];
    }

    useHead({
      title: "Home",
      titleTemplate: "Mixo | %s",
      meta: [
        {
          name: "description",
          content:
            "Generate a websites and launch online in seconds with the world's fastest AI website builder.",
        },
        {
          name: "theme-color",
          content: "#f1f5f9",
        },
        { name: "og:title", content: "Mixo" },
        { name: "og:url", content: route.path },
        { name: "twitter:url", content: route.path },
        { name: "og:site_name", content: "Mixo" },
        { name: "og:type", content: "website" },
        {
          name: "og:description",
          content:
            "Generate a websites and launch online in seconds with the world's fastest AI website builder.",
        },
        {
          name: "og:image",
          content:
            "https://storage.googleapis.com/mixo-sites/images/file-2ea8702c-b5e0-4026-80fc-d60d790bb5b0.png",
        },
        { name: "og:image:alt", content: "Mixo.io" },
        { name: "twitter:title", content: "Mixo" },
        {
          name: "twitter:description",
          content: "AI-powered page builder to launch and validate your startup ideas.",
        },
        { name: "twitter:card", content: "summary_large_image" },
        {
          name: "twitter:image:src",
          content:
            "https://storage.googleapis.com/mixo-sites/images/file-2ea8702c-b5e0-4026-80fc-d60d790bb5b0.png",
        },
      ],
      link: [
        {
          rel: "icon",
          type: "image/svg+xml",
          href: computed(() => {
            if (APP_ENV === "local") {
              return "/favicon-local.svg";
            }

            if (APP_ENV === "qa") {
              return "/favicon-qa.svg";
            }

            return "/favicon.svg";
          }),
        },
      ],
      htmlAttrs: {
        class: DEVELOPMENT ? "debug-screens" : "",
      },
      script: scripts,
    });

    const MAINTENANCE_MODE = false;
    const MIXO_ADMIN =
      typeof window !== "undefined" &&
      window?.localStorage.getItem("mixo-admin") === "true";

    const network = useNetwork();

    return {
      EmailVerification,
      isPreviewRoute,
      isSidebarActive,
      network,
      Maintenance,
      Sidebar,
      MAINTENANCE_MODE,
      MIXO_ADMIN,
    };
  },
};
</script>

<template>
  <div
    v-if="!network?.isOnline?.value"
    class="text-center p-1 md:ps-3 bg-red-100 text-sm text-red-800 border-b border-red-200"
  >
    It looks like you're not connected to the internet. Please reconnect before
    continuing.
  </div>

  <EmailVerification />

  <ReloadPrompt />

  <ModalsContainer />

  <Sidebar v-if="!isPreviewRoute" @close="isSidebarActive = false" />

  <Maintenance v-if="MAINTENANCE_MODE && !MIXO_ADMIN" />

  <RouterView v-else :class="isSidebarActive ? 'opacity-70' : ''" />
</template>
