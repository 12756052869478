<template>
  <div class="flex justify-center items-center" data-testid="ui-center">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiCenter',
}
</script>

<style scoped>
.ui-center {
  /* Base Styles */
}
</style>
