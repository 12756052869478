import { logEvent, setUserProperties } from 'firebase/analytics'
import { analytics } from '@/modules/firebase.js'
import { DEVELOPMENT } from '~/utils/constants.js'

const GA_EVENTS = {
  OnboardingStart: 'onboarding_start',
  OnboardingComplete: 'onboarding_complete',
  NewCustomer: 'new_customer',
  Publish: 'site_publish',
  ViewPlans: 'view_plans',
  Checkout: 'begin_checkout', // Measure the 1st step in a checkout process by logging a begin_checkout event with one or more items defined with the relevant fields.
  Purchase: 'purchase',
}

const logAnalytics = (eventLabel, eventName, eventData = {}) => {
  console.groupCollapsed(
    `🟠%c ${eventLabel}:`,
    'color: #00b8f9',
    eventName,
  )
  console.group('Event Data')
  if (Object.entries(eventData).length < 3) {
    for (const [k, v] of Object.entries(eventData))
      console.log(k, v)
  }
  else {
    // Or the rest folded
    console.log(eventData)
  }
  console.groupEnd()
  console.groupEnd()
}

/**
 * Capture an event in
 *
 * @param {*} eventName
 * @param {*} [eventData={}]
 */
const trackEvent = (eventName, eventData = {}) => {
  if (DEVELOPMENT) {
    logAnalytics("Track Event Fired (on Dev)", eventName, eventData)
    return
  }

  if (analytics == null)
    return

  logEvent(analytics, eventName, eventData, {})
}

/**
 * User properties are attributes you define to describe segments of your user base,
 * such as language preference or geographic location.
 * These can be used to define audiences for your app. This guide shows you how to
 * set user properties in your app.
 *
 * @param {*} [propertyData={}]
 */
const trackUserProperty = (type, value) => {
  if (DEVELOPMENT) {
    logAnalytics("Track User Property Fired (on Dev)", type, value)
    return
  }

  if (analytics == null)
    return

  setUserProperties(analytics, { [type]: value })
}

/**
 * Measure a purchase by logging a purchase event with one or more items defined with the relevant fields
 * @param {*} eventName
 * @param {*} [eventData={}]
 */
const trackPurchase = async (uId, priceId) => {
  const value = 0;

  // Example event Data:
  const eventData = {
    transaction_id: `${uId}-${priceId}`,
    currency: "USD",
    plan: priceId,
    value, // Total Revenue
    tax: 0,
    shipping: 0,
  }
  trackEvent(GA_EVENTS.Purchase, eventData)
}

export { trackEvent, trackPurchase, trackUserProperty, GA_EVENTS }
