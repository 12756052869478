<template>
  <div class="bg-white z-50 h-screen w-screen">
    <div class="w-full bg-slate-100 py-2 md:py-4">
      <div class="flex justify-between px-2 md:px-4">
        <div class="flex items-center space-x-2 md:space-x-4">
          <slot name="pre" />

          <BrandLogo />
          <slot />
        </div>

        <div class="flex items-center space-x-2 md:space-x-4 justify-end">
          <SupportLink>Contact Support</SupportLink>
        </div>
      </div>
    </div>

    <div class="mt-10 flex flex-col items-center">
      <section class="lg:px-8 bg-slate-50 py-16 px-6 rounded-xl shadow">
        <div class="mx-auto max-w-2xl flex flex-col items-center space-y-10">
          <h1
            class="text-2xl font-extrabold leading-8 tracking-tight text-slate-900 sm:text-3xl sm:leading-9"
          >
            We'll be right back 👨‍💻🤖
          </h1>
          <h2
            class="text-xl font-semibold leading-8 tracking-tight text-slate-700 sm:leading-9"
          >
            &ldquo;Hi there, we're just working on some important updates and we'll be
            back online shortly. Please
            <SupportLink>reach out</SupportLink> if you have any issues or would like to
            be updated when we're back online.&rdquo;
          </h2>

          <BrandLogo />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup></script>
