<script setup>
import { Icon } from "@iconify/vue";
import { asyncComponentDefaults, showModal } from "@/composables";

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(["input"]);

// Refs
const mediaInputRef = ref(null);

// Injections
const $vfm = inject("$vfm");

// Composables
const [thumbLoading] = useToggle();

const MediaLibrary = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/MediaLibrary/MediaLibrary.vue"),
});

// Data
const thumburl = eagerComputed(() => {
  const value = props?.context?._value;
  if (typeof value === "string") return value;

  // If we have an image return it's src
  if (value?.image?.src) {
    return value?.image?.src;
  }

  // If we have a video return it's thumbnailUrl
  if (value?.video?.thumbnailUrl) {
    return value?.video?.thumbnailUrl;
  }

  return null;
});

const icon = eagerComputed(() => {
  const value = props?.context?._value;

  // If we have an icon return the icon
  if (value?.icon) {
    thumbLoading.value = false;
    return value?.icon;
  }

  return null;
});

// Methods
const emitNativeBlur = () => {
  // Create a new blur event
  const blurEvent = new Event("blur");

  // Dispatch the event
  mediaInputRef.value.dispatchEvent(blurEvent);
};

const onInput = async (input) => {
  thumbLoading.value = true;

  props?.context?.node?.input(input);

  // Wait for the media library modal to close
  await $vfm.hide("media-library");

  // Then emit a blur event
  // We do this so that formkit can update the state machine's value before we let the state machine know a field has blurred. This is so the autosave can work properly due to the guard on the blur event in the machine.
  emitNativeBlur();
};

const onMediaClick = () => {
  showModal(
    {
      name: "media-library",
      clickToClose: false,
      contentClasses: "w-full max-w-4xl",
    },
    {
      default: {
        component: MediaLibrary,
        bind: {
          enableUnsplash: props?.context?.enableUnsplash,
          enableUploader: props?.context?.enableUploader,
          enableVideo: props?.context?.enableVideo,
          enableIcons: props?.context?.enableIcons,
          returnImagePathAsString: props?.context?.returnImagePathAsString,
          openAuthModal: props?.context?.openAuthModal,
        },
        on: {
          input: onInput,
        },
      },
    }
  );
};

const onThumbLoad = () => {
  thumbLoading.value = false;
};

const onRemoveClick = () => {
  props?.context?.node?.input();

  setTimeout(() => {
    emitNativeBlur();
  }, 750);
};
</script>

<template>
  <div
    ref="mediaInputRef"
    class="formkit-outer formkit-disabled:opacity-50"
    data-testid="media-input"
    data-formkit-custom-input
    :name="context?.node?.name"
  >
    <div class="formkit-wrapper flex flex-col space-y-4">
      <div
        v-if="context?._value"
        class="formkit-inner max-w-md formkit-invalid:border-red-500 mb-1 flex flex-col items-start space-y-2"
      >
        <button
          class="appearance-none cursor-pointer border border-slate-300 rounded-lg p-2 bg-white"
          type="button"
          @click="onMediaClick"
        >
          <UiLoader v-if="thumbLoading" />

          <img
            v-show="!thumbLoading && !icon"
            :key="context?._value"
            :src="thumburl"
            :alt="context?.label"
            :onload="onThumbLoad"
            class="w-full max-w-[8rem]"
          />

          <Icon v-if="icon" :icon="icon" class="w-8 h-auto text-current" />
        </button>

        <button
          v-if="!thumbLoading"
          class="text-xs text-red-700"
          type="button"
          @click="onRemoveClick"
        >
          (remove)
        </button>
      </div>

      <UiButton
        v-if="!context?._value"
        type="button"
        theme="brand-secondary"
        size="sm"
        class="self-start"
        @click="onMediaClick"
      >
        <IconFluentImage24Regular class="w-4" />
        <span>Add</span>
      </UiButton>
    </div>
  </div>
</template>
