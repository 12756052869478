<template>
  <!-- Logged In state -->
  <div v-if="currentUser" class="assistant space-y-6" data-testid="assistant">
    <FormKit
      v-if="!showSupportForm"
      type="form"
      :actions="false"
      :disabled="['ask', 'responding'].includes(status)"
      form-class="!space-y-1"
      @submit="onSubmit"
    >
      <div class="flex-row-center gap-2">
        <FormKit
          v-model="input"
          type="text"
          name="input"
          label="Ask your question here"
          placeholder="How can we help?"
          required
          outer-class="flex-1 !mb-0"
        />
      </div>

      <div class="flex flex-wrap items-center justify-between text-sm">
        <p class="text-slate-500 flex gap-1">
          <span>Press <span class="font-semibold">Enter</span> to ask</span>
          <button
            type="button"
            class="default-link text-xs italic flex-row-center gap-1"
            @click="toggleDisclaimer()"
          >
            <IconTablerSparkles />
            <span>Powered by Mixo AI</span>
          </button>
        </p>

        <button
          v-if="messages?.length && !['ask', 'responding'].includes(status)"
          type="button"
          class="default-link"
          @click="toggleHistory()"
        >
          {{ showHistory ? "Hide" : "Show" }} history
        </button>
      </div>
    </FormKit>

    <p v-if="showDisclaimer" class="text-sm text-slate-500 italic">
      Mixo's support is powered by AI to assist you in finding the right answer to your
      question. We recommend using it alongside our documentation for optimal results. Be
      aware that the AI is still in beta and can occasionally make suggestions which may not be optimal / not
      true.
    </p>

    <AssistantHistory
      v-if="showHistory && !showSupportForm"
      :messages="messages"
      @select-message="onSelectMessage"
    />

    <MagicStatus
      v-if="['ask', 'responding'].includes(status) || assistantState.matches('asking')"
      :animating="true"
    >
      <div v-if="['ask'].includes(status)" class="flex-row-center gap-3 p-4 text-lg">
        <IconTablerMessage2Up />
        <p>
          Sending Question&hellip;
        </p>
      </div>
      <div v-else-if="['responding'].includes(status)" class="flex-row-center gap-3 p-4 text-lg">
        <IconTablerMessageBolt />
        <p>
          Support Responding&hellip;
        </p>
      </div>
      <div v-else class="flex-row-center gap-3 p-4 text-lg">
        <IconTablerPlugConnected />
        <p>
          Connecting to Support&hellip;
        </p>
      </div>
    </MagicStatus>

    <AssistantMessages
      v-if="
        !showSupportForm &&
          activeMessage?.user &&
          activeMessage?.assistant &&
          !assistantState.matches('asking')
      "
      :active-message="activeMessage"
      :sending-feedback="assistantState.matches('sendingFeedback')"
      @button-click="onButtonClick"
      @bad-feedback="onBadFeedback"
    />

    <Suspense>
      <SupportForm
        v-if="showSupportForm"
        :type="supportFormType"
        :assistant-message="activeMessage"
        :message="activeMessage?.user"
        :show-type="true"
        @close="onSupportFormClose"
        @submit="onSupportFormSubmit"
      />
    </Suspense>
  </div>
  <!-- Logged Out state -->
  <div v-else>
    <UiButton theme="brand-link" href="login">
      Sign up or Login
    </UiButton> to access Mixo's
    support options
  </div>
</template>

<script setup>
import { fromContext, setupMachine } from "@/composables";
import AssistantMachine from "@/machines/assistant-machine.js";
import { defineAsyncComponent } from "vue";
import { asyncComponentDefaults } from "^/composables";
import { useCurrentUser } from "vuefire";

const SupportForm = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/SupportForm/SupportForm.vue"),
});

// Injections
const toast = inject("$toast");

// State
const { state: assistantState, send: sendToAssistantService } = setupMachine(
  AssistantMachine,
  "Assistant",
  {
    context: {
      toast,
    },
  }
);

// Data
const input = ref("");
const supportFormType = ref("");
const messages = fromContext(assistantState, "messages", []);
const activeMessage = fromContext(assistantState, "activeMessage");
const status = fromContext(assistantState, "assistantData.status");
const [showSupportForm] = useToggle(false);
const [showHistory, toggleHistory] = useToggle(false);
const [showDisclaimer, toggleDisclaimer] = useToggle(false);

// Composables
const route = useRoute();
const currentUser = useCurrentUser();

// Methods
const onSubmit = (value) => {
  input.value = "";

  sendToAssistantService("ASK_ASSISTANT", { input: value.input, url: route.path});
};

const setupSupportForm = (type) => {
  showSupportForm.value = true;
  supportFormType.value = type;
};

const onButtonClick = (button) => {
  if (button?.action === "loadLink") {
    window.open(button?.link, "_blank");
    return;
  }

  setupSupportForm(button?.type);
};

const onBadFeedback = (messageId) => {
  sendToAssistantService("BAD_FEEDBACK_RECEIVED", { messageId });
};

const onSupportFormClose = () => {
  showSupportForm.value = false;
  supportFormType.value = "";
};

const onSupportFormSubmit = () => {
  showSupportForm.value = false;
  supportFormType.value = "";
};

const onSelectMessage = (id) => {
  sendToAssistantService("SELECT_MESSAGE", { id });

  showHistory.value = false;
};
</script>
