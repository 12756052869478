<template>
  <button class="default-link cursor-pointer" data-testid="support-link" @click="onClick">
    <slot>contact support</slot>
  </button>
</template>

<script setup>
const isSidebarActive = inject("isSidebarActive");

const emit = defineEmits(["clicked"]);

const onClick = () => {
  isSidebarActive.value = true;
  emit("clicked");
};
</script>
