<script setup>
import { useCurrentUser } from "vuefire";
const props = defineProps({
  fullWidth: {
    type: Boolean,
    default: true,
  },
});

// Composables
const currentUser = useCurrentUser();

const toggleSidebar = inject("toggleSidebar");
</script>

<template>
  <div
    class="w-full bg-slate-50 border-t border-slate-200 xl:border-0 py-2 md:py-4 shadow-sm z-10"
  >
    <div
      class="flex justify-between"
      :class="{ container: !fullWidth, 'px-2 md:px-4': fullWidth }"
    >
      <div class="flex items-center space-x-1 sm:space-x-2 md:space-x-4">
        <slot name="pre" />

        <BrandLogo />

        <UiButton
          v-if="currentUser"
          theme="brand-link"
          size="sm"
          class="p-0"
          to="/sites"
        >
          <IconTablerLayoutList class="w-4 hidden sm:inline" />
          <span>Sites</span>
        </UiButton>
        <slot />
      </div>

      <div class="flex items-center space-x-1.5 sm:space-x-2 md:space-x-4 justify-end">
        <slot name="meta" />

        <UiButton
          theme="brand-link"
          size="sm"
          class="p-0"
          @click="toggleSidebar()"
        >
          <IconTablerHelp class="w-6 h-6 md:h-7 md:w-7" />

          <span class="sr-only">Support</span>
        </UiButton>

        <Suspense>
          <AccountDropdown />
        </Suspense>
      </div>
    </div>
  </div>
</template>
