import { cloneDeep  } from 'lodash-es';


const mergeUpdates = (oldObj, newObj) => {
  if (newObj === undefined) {
    return oldObj;
  }

  // We use cloneDeep to avoid mutating the original object and to remove reactivity
  const returnData = { ...cloneDeep(oldObj), ...cloneDeep(newObj) }

  return returnData;
};

export { mergeUpdates }
