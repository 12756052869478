<template>
  <div class="space-y-2">
    <UiHeading :level="6">
      Previous Questions
    </UiHeading>

    <div
      class="space-y-4 max-h-28 overflow-auto bg-slate-100 ring-1 ring-slate-200 p-3 rounded-lg"
    >
      <button
        v-for="(message, index) in filteredMessages"
        :key="message?.id || index"
        type="button"
        class="flex-row-center gap-1 text-sm text-slate-700 hover:text-brand-primary w-80"
        :title="message?.user"
        @click="onSelectMessage(message)"
      >
        <div><IconTablerHistory class="w-4 h-4" /></div>
        <div class="text-left">
          {{ message?.user }} 
        </div>
      </button>
    </div>
  </div>
</template>

<script setup>
import has from "lodash-es";

const props = defineProps({
  messages: {
    type: Array,
    default() {
      return [];
    },
  },
});

const emit = defineEmits(["select-message"]);

// Data
// reverse the messages array so the most recent message is at the top
const filteredMessages = eagerComputed(() =>
  props.messages.filter((message) => has(message, "user"))
);

const onSelectMessage = (message) => {
  emit("select-message", message?.id);
};
</script>
