import { doc, getDoc, updateDoc,  onSnapshot } from 'firebase/firestore'
import { firestore as db } from '@/modules/firebase.js'

/**
 * Subscribe to an assistant conversation with realtime updates
 *
 * @param {string} uid
 * @returns {object} assistant
 */
const subscribeWithUid = async (uid, callback = null, errorHandler = null) => {
  const assistantRef = doc(db, 'assistant', uid)

  return onSnapshot(assistantRef, callback, errorHandler);
}

/**
 * Update an assistant message when a conversation has been marked as incorrect
 * @param {string} uid
 * @param {string} messageId
 * @returns {object} assistant
 */
const flagAssistantMessage = async (uid, messageId) => {
  const assistantRef = doc(db, 'assistant', uid)
  const docSnap = await getDoc(assistantRef)
  let data = docSnap.data();

  let messages = data?.messages;

  // Find the index of the object with the matching id
  let indexToUpdate = messages.findIndex(obj => obj.id === messageId);

  if (indexToUpdate !== -1) {
    // Update the object
    messages[indexToUpdate].flaggedByUser = true;
  }

  // Update the assistant
  data.messages = messages;

  return await updateDoc(assistantRef, data);
}

export { subscribeWithUid, flagAssistantMessage }
