const APP_ENV = import.meta.env.VITE_APP_ENV
const DEVELOPMENT = import.meta.env.DEV || APP_ENV === "qa"
const PRODUCTION = import.meta.env.PROD
const SSR = import.meta.env.SSR
const MIXO_DOMAIN = import.meta.env.VITE_MIXO_DOMAIN
const APP_URL = import.meta.env.VITE_APP_URL
const ADMIN_STORAGE_FLAG = "mixo-admin"
const MIXO_ADMIN = typeof window !== "undefined" && window?.localStorage.getItem("mixo-admin") === "true";

export { ADMIN_STORAGE_FLAG, APP_ENV, APP_URL, DEVELOPMENT, MIXO_ADMIN, MIXO_DOMAIN, PRODUCTION, SSR }
