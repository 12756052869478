import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createHead } from "@vueuse/head"
import { setupLayouts } from 'virtual:generated-layouts'
import { getCurrentUser } from 'vuefire'
import {setupErrorTracking, errorHandler} from '@/utils/error-tracking.js'
import App from './App.vue'
import generatedRoutes from '~pages'
import { PRODUCTION } from '~/utils/constants'

import './styles/tailwind.css'
import './styles/main.css'
import '~/styles/base.css'

const app = createApp(App)

// Setup Routing
const routes = setupLayouts(generatedRoutes)
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
// Configure router
router?.beforeEach(async (to) => {
  // routes with `meta: { requiresAuth: true }` will check for the users, others won't
  if (to?.meta?.requiresAuth) {
    const currentUser = await getCurrentUser()

    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: '/login',
        query: {
          // we keep the current path in the query so we can redirect to it after login
          // with `router.push(route.query.redirect || '/')`
          redirect: to.fullPath,
        },
      }
    }
  }
})
app.use(router)

// Setup error tracking
PRODUCTION && setupErrorTracking()

// Setup errorHandler
app.config.errorHandler = errorHandler;

// Setup vueuse/head
const head = createHead()
app.use(head)

// Install all modules under `modules/`
Object.values(import.meta.globEager('./modules/*.js')).forEach(i => i.install?.({app, router}))

// Mount the app
app.mount('#app')

