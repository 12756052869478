<template>
  <TransitionRoot as="template" :show="isSidebarActive">
    <Dialog as="div" class="relative z-10" @close="onCloseClick">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div
                  class="flex h-full flex-col overflow-y-scroll bg-slate-50 py-6 shadow-xl border-l border-slate-200"
                >
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle
                        class="text-lg md:text-xl font-bold leading-6 text-gray-700"
                      >
                        Customer Support
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-2 focus:bg-white"
                          @click="onCloseClick"
                        >
                          <span class="sr-only">Close panel</span>
                          <IconHeroiconsXMark class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Main Content -->
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <Assistant />

                    <div class="flex flex-col items-start gap-4 mt-8">
                      <hr class="h-px bg-gray-200 border-0 w-full rounded-full" />

                      <a
                        href="https://www.mixo.io/help"
                        target="_blank"
                        class="flex-row-center gap-x-2 text-slate-600 hover:text-brand-primary"
                      >
                        <IconTablerHelp />

                        <span>Help &amp; Documentation</span>
                      </a>

                      <a
                        href="/support/submit"
                        class="flex-row-center gap-x-2 text-slate-600 hover:text-brand-primary"
                      >
                        <IconTablerUserQuestion />

                        <span>Report a Bug or Issue</span>
                      </a>

                      <a
                        href="https://feedback.mixo.io"
                        target="_blank"
                        class="flex-row-center gap-x-2 text-slate-600 hover:text-brand-primary"
                      >
                        <IconTablerPointerQuestion />

                        <span>Submit Feature Request</span>
                      </a>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { differenceInCalendarDays } from "date-fns";

const emit = defineEmits(["close"]);

const daysSince = differenceInCalendarDays(
  new Date(),
  new Date(2023, 9, 1, 0, 0)
);

const isSidebarActive = inject("isSidebarActive");

const onCloseClick = () => {
  emit("close");
};
</script>
