import mixpanel from "mixpanel-browser";
import { H } from 'highlight.run';
import { DEVELOPMENT } from '~/utils/constants.js'


const logTracking = (eventLabel, eventName, eventData = {}) => {
  if(DEVELOPMENT){
    console.groupCollapsed(
      `🟠%c ${eventLabel}:`,
      'color: #00b8f9',
      eventName,
    )
    console.group('Event Data')
    if (Object.entries(eventData).length < 3) {
      for (const [k, v] of Object.entries(eventData))
        console.log(k, v)
    }
    else {
      // Or the rest folded
      console.log(eventData)
    }
    console.groupEnd()
    console.groupEnd()
  }
}

/**
 * Identify a user by their unique id
 * @param {*} userId
 * @param {*} email
 * @param {*} displayName
 * @returns
*/
const identify = (userId, email, displayName) => {
  mixpanel.identify(userId);
  if(email) mixpanel.people.set({ $email: email });
  if(displayName) mixpanel.people.set({ $name: displayName });

  if (userId && email) {
    H.identify(email, {
      id: userId,
      displayName,
    });
  }
};

/**
 * Track an event with optional properties
 * @param {*} eventName
 * @param {*} [properties={}]
 * @returns
*/
const track = (event, properties = {}) => {
  mixpanel.track(event, properties);
  logTracking("Mixpanel Event Fired", event, properties);
};

const trackPage = () => {
  mixpanel.track_pageview();
};

/**
 * You can register these properties as super properties. If you tell us just once that 
 * these properties are important, we will automatically include them with all events sent
 *
 * @param {*} [properties={}]
 */
const trackProperties = (properties = {}) => {
  mixpanel.register(properties);
  logTracking("Mixpanel Track Properties Fired", "Properties", properties);
};


/**
 * Measure a purchase by logging a purchase event with one or more items defined with the relevant fields
 * @param {*} eventName
 * @param {*} [eventData={}]
 */
const trackPurchase = async (uId, priceId) => {
  const eventData = {
    distinct_id: uId,
    priceId,
  }
  mixpanel.track('purchase', eventData);
  logTracking("Mixpanel Purchase Fired", `PriceId ${priceId}`, eventData);
}

export { identify, track, trackPurchase, trackProperties, trackPage };
